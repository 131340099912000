html {
   scroll-behavior: smooth;
   overflow-x: hidden;
}
/* * {
   outline: 1px solid red; 
} */
body {
   overflow-x: hidden;
   overflow: hidden;
   font-family: "Gilroy", sans-serif;
}
button {
   font-family: "Gilroy", sans-serif;
}

.full-page-background {
   background-image: url("../public/images/background.png"); /* Path relative to the public folder */
   background-size: contain;
   background-position: center;
   background-repeat: repeat;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background-color: #222121;
}
.container {
   width: 90%;
   margin: auto;
}

/* INTRO - BLOCK */
#intro {
   overflow: hidden;
}
.logo {
   position: absolute;
   padding: 2% 0;
}
#texture1,
#texture2,
#texture3 {
   position: absolute;
   z-index: 1;
   right: 0;
}
#texture1 {
   margin-right: -40%;
}
#texture2 {
   margin-right: 15%;
}
#texture3 {
   margin-right: -13%;
}
@media screen and (max-width: 770px) {
   #texture1,
   #texture2,
   #texture3 {
      display: none;
   }
}
.main {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}
/* #intro::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 10%;
   background: linear-gradient(
      to top,
      #222121,
      #222121,
      transparent
   ); 
   z-index: 3;
   margin-top: 1%;
} */
.main .text {
   display: flex;
   flex-direction: column;
   color: #f7f7f7;
   width: 50%;
   z-index: 2;
}
.main .text h1 {
   font-weight: 800;
   font-size: 48px;
   line-height: 60px;
}
.main .text h1 span {
   color: #cfa46c;
}
.main .text p {
   font-size: 24px;
   font-weight: 400;
}
#calltoaction {
   background-color: #cfa46c;
   color: #222121;
   padding: 19px 20px;
   text-decoration: none;
   align-items: center;
   width: fit-content;
   font-weight: 500;
   font-size: 24px;
   line-height: 28.25px;
   border-radius: 20px;
   transition: 0.3s;
   border: none;
}

#calltoaction img {
   margin: 0 0 0 10px;
   align-items: center;
   width: 17.5px;
}
#calltoaction:hover {
   transform: scale(1.05);
   /* font-size: 26px; */
}

.main .avatar {
   width: 49%;
   max-width: 49%;
   text-align: center;
   z-index: 2;
}
.main .avatar img {
   width: 90%;
}

@media screen and (max-width: 1367px) {
   .main .text h1 {
      font-size: 36px;
      line-height: 36.75px;
   }
   #calltoaction {
      font-size: 18px;
      line-height: 19.25px;
   }
}
@media screen and (max-width: 768px) {
   .logo {
      padding: 5% 0;
   }
   .main .avatar {
      margin-top: 13vh;
      width: 100vw;
      text-align: left;
      max-width: 100vw;
   }
   .main .avatar img {
      width: 100vw;
   }
   .main {
      flex-direction: column-reverse;
   }
   .main .text {
      width: 100%;
   }
   .main .text h1 {
      font-size: 28px;
      line-height: 29.25px;
      margin-top: 0;
   }
   .main .text p {
      font-size: 18px;
      line-height: 18.75px;
   }
   .main .text a {
      margin: auto;
   }
}
/* YOULLGET BLOCK */
#youllget {
   padding-top: 10vh;
}
.youllget {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
}
#title {
   min-width: 35%;
   width: 40%;
   max-width: 40%;
   color: #a0a0a0;
   font-weight: 400;
   font-size: 48px;
   line-height: 57.6px;
   margin: 0 8% 0 0;
}
@media screen and (max-width: 1536px) {
   #title {
      font-size: 42px;
      line-height: 45px;
   }
}
@media screen and (max-width: 768px) {
   #title {
      font-size: 36px;
      line-height: 38.25px;
      width: 100%;
      max-width: 100%;
      margin-bottom: 5vh;
      color: #cfa46c;
   }
}
.youllget .right {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.youllget .text {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* height: 27vh; */
   gap: 3.7vh;
}
.youllget .text p {
   font-size: 20px;
   font-weight: 400;
   line-height: 24px;
   color: #a0a0a0;
   margin: 0;
}
.youllget .text #calltoaction {
   padding: 19px 60px;
   bottom: 0;
}
.right .bigfour {
   display: flex;
   flex-wrap: wrap;
   gap: 30px;
   width: 100%;
   margin-top: 7.5vh;
}
.right .bigfour .item {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: transparent;
   color: #a0a0a0;
   padding: 20px;
   width: 40%;
   border: #a0a0a0 solid 0.5px;
   border-radius: 20px;
   transition: 0.3s;
}
.right .bigfour .item #icon-text {
   font-size: 24px;
   line-height: 28.25px;
   font-weight: 500;
   width: 90%;
   color: #f7f7f7;
}
.right .bigfour .item .line {
   width: 90%;
   height: 0.5px;
   background-color: #f7f7f7;
   margin: 10% 0 5%;
}
.right .bigfour .item:hover #icon-text {
   color: #222121;
}
.right .bigfour .item:hover {
   background-color: #cfa46c;
   transform: scale(1.05);
}
.right .bigfour .item:hover .line {
   color: #222121;
}
.right .bigfour .item .icon #img {
   display: none;
}
.right .bigfour .item:hover .icon #img-white {
   display: none;
}
.right .bigfour .item .icon {
   padding: 5%;
   border-radius: 50px;
   border: #a0a0a0 solid 0.5px;
   width: fit-content;
}
.right .bigfour .item:hover .icon #img {
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   display: flex;
}
.right .bigfour .item:hover .icon {
   border: #222121 solid 0.5px;
}
@media screen and (max-width: 1366px) {
   .right .bigfour .item {
      padding: 20px;
   }
}
@media screen and (max-width: 768px) {
   .youllget {
      flex-direction: column;
   }
   .youllget .text p {
      font-size: 16px;
      line-height: 18px;
   }
   .youllget .text a {
      margin: auto;
   }
   .right .bigfour .item {
      width: 100%;
   }
}

/* FORYOU BLOCK */
#foryou {
   padding-top: 10vh;
}
.foryou {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
}
.foryou .text {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 35vh;
   padding-top: 0.8%;
   width: 75%;
}
.foryou .text .item {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   padding-bottom: 2%;
   border-bottom: 0.5px solid #a0a0a0;
   transition: 0.3s;
}
.foryou .text .item h4 {
   font-size: 24px;
   line-height: 28.8px;
   color: #f7f7f7;
   margin: 0;
   font-weight: 400;
}
.foryou .text .item h4 span {
   color: #cfa46c;
}
.foryou .text .item:hover {
   transform: scale(1.03);
}
@media screen and (max-width: 768px) {
   .foryou {
      flex-direction: column;
   }
   .foryou .text {
      width: 100%;
   }
   .foryou .text .item h4 {
      font-size: 18px;
      line-height: 19.75px;
   }
}
@media screen and (max-width: 380px) {
   .foryou .text .item h4 {
      width: 70%;
   }
   .foryou .text {
      height: 50vh;
   }
}
/* PRICE BLOCK */
#price {
   padding-top: 10vh;
}
.prices {
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   margin-top: 5vh;
   gap: 30px;
   /* height: 70vh; */

   position: relative;
}
.price {
   background-color: #cfa46c;
   border-radius: 30px;
   padding: 0 3%;
   min-height: 100vh;
   /* height: 100vh; */
   height: fit-content;
   transition: 0.5s;

   width: 100%;
   display: block;
}
.price.active {
   display: block;
}
.price:hover {
   transform: scale(1.05);
}
.price .content {
   margin: 20% 0;
   height: 90vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.sale {
   display: flex;
   flex-direction: column;
   height: fit-content;
}
.price #old-price {
   color: #f7f7f7;
   font-size: 36px;
   font-weight: 600;
   line-height: 42.95px;
   margin: 0 0 40px 0;
   position: relative;
   width: 200px;
}
.old-price img {
   position: absolute;
   display: flex;
   text-align: left;
   justify-content: flex-start;
   top: 0;
   width: 150px;
   z-index: 4;
}
@media screen and (max-width: 1367px) {
   .old-price img {
      margin-top: -7%;
   }
}
.price #new-price {
   color: #222121;
   font-size: 48px;
   font-weight: 600;
   line-height: 57.26px;
   margin: 0;
   z-index: 5;
}
.price p {
   font-size: 20px;
   font-weight: 400;
   line-height: 24px;
}
/* .price .calltoaction {
   background-color: #f7f7f7;
   text-decoration: none;
   width: 100%;
   padding: 15px 0;
   text-align: center;
   border-radius: 20px;
} */
.price #calltoaction {
   background-color: #f7f7f7;
   text-decoration: none;
   width: 100%;
   padding: 15px 0;
   text-align: center;
   border-radius: 20px;
   text-decoration: none;
   font-size: 18px;
   font-weight: 500;
   line-height: 28.25px;
   color: #222121;
   border: none;
}
.price #calltoaction img {
   width: 15px;
}
/* .calltoaction a {
   text-decoration: none;
   font-size: 24px;
   font-weight: 500;
   line-height: 28.25px;
   color: #222121;
} */
.slider-buttons {
   display: flex;
   justify-content: space-between;
   margin-top: 2vh;
}

.slider-buttons button {
   padding: 10px 20px;
   background-color: transparent;
   border: none;
   border-radius: 10px;
   cursor: pointer;
}
@media screen and (max-width: 1536px) {
   .price {
      padding: 0 3%;
      min-height: 100vh;
      height: fit-content;
   }
   .price .content {
      margin: 20% 0;
      height: 90vh;
   }
   .price #old-price {
      font-size: 24px;
      line-height: 29.85px;
      margin: 0 0 30px 0;
   }
   .price #new-price {
      text-align: center;
      font-size: 30px;
      line-height: 37px;
   }
   .price p {
      font-size: 16px;
      line-height: 16.75px;
   }
   .calltoaction a {
      font-size: 18px;
      line-height: 18.25px;
   }
}

@media (max-width: 768px) {
   body {
      max-width: 100vw;
      overflow: hidden;
   }
   .prices {
      flex-direction: column;
   }
   .price {
      display: block;
      min-height: 50vh;
      height: max-content;
   }
   .price-active {
      display: none;
      width: 100%;
      padding: 0 3%;
      height: fit-content;
      transition: 0.5s;

      width: 100%;
      display: block;
   }
   .prices .price .content {
      height: 70vh;
   }
   .slider-buttons {
      display: none;
   }
}

@media screen and (min-width: 769px) {
   .price {
      width: 30%;
   }

   .price.active {
      display: block;
   }

   .slider-buttons {
      display: none;
   }
}
@media screen and (max-width: 380px) {
   .price .content {
      margin: 10% 0;
   }
   .prices .price .content {
      height: 100vh;
   }
}

/* WHYAREWE BLOCK */
#whyarewe {
   padding-top: 10vh;
}
.whyarewe {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.whyarewe .items {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 30px;
   margin-left: -0.9%;
   /* justify-content: end; */
}
.whyarewe .items .item {
   display: flex;
   flex-direction: column;
   width: 275px;
   padding: 15px 15px 0 20px;
   justify-content: space-around;
   background-color: #f7f7f7;
   border-radius: 20px;
   height: 217px;
}
.whyarewe .item img {
   width: 80px;
   top: 0;
}
.whyarewe .items .item p {
   font-size: 24px;
   font-weight: 400;
   line-height: 28.8px;
   color: #222121;
   margin: 0;
}
@media screen and (max-width: 1536px) {
   .whyarewe .items {
      gap: 30px;
   }
   .whyarewe .items .item {
      width: 25%;
      padding: 12px 16px;
      height: 138px;
   }
   .whyarewe .item img {
      width: 58px;
   }
   .whyarewe .items .item p {
      font-size: 18px;
      line-height: 18.75px;
   }
}
@media screen and (max-width: 768px) {
   .whyarewe {
      flex-direction: column;
   }
   .whyarewe .items {
      width: 100%;
      justify-content: center;
   }
   .whyarewe .items .item {
      width: 38%;
      padding: 8px 10px;
   }
   .whyarewe .item img {
      width: 40px;
   }
   .whyarewe .items .item p {
      font-size: 16px;
      line-height: 18.75px;
   }
}

/* FEATURES BLOCK */
#features {
   padding: 10vh 0;
   margin-bottom: 10vh;
   height: fit-content;
}
.features {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.features .content {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-end;
   gap: 26px;
}
.features .content .item {
   background-color: transparent;
   border: 0.5px solid #f7f7f7;
   border-radius: 30px;
   padding: 20px 0;
   width: 48%;
   height: 50%;
}
.features .content .item .container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 70%;
}
.features .content .item .text {
   height: 35%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   bottom: 0;
}
.features .content .item .number {
   background-color: #f7f7f7;
   border-radius: 40px;
   padding: 20px;
   width: fit-content;
   font-size: 40px;
   font-weight: 400;
   line-height: 48px;
   color: #222121;
   margin: 0;
}
.features .content .item h3 {
   font-size: 36px;
   line-height: 43.2px;
   font-weight: 400;
   color: #f7f7f7;
   margin: 15px 0;
}
.features .content .item p {
   font-size: 20px;
   line-height: 24px;
   font-weight: 400;
   color: #f7f7f7;
   margin: 8px 0;
}
@media screen and (max-width: 1536px) {
   .features .content .item {
      padding: 15px 0;
      width: 46%;
      height: 50%;
   }
   .features .content .item .number {
      padding: 20px;
      font-size: 32px;
      line-height: 32px;
   }
   .features .content .item h3 {
      font-size: 24px;
      line-height: 24.75px;
      margin: 15px 0;
   }
   .features .content .item p {
      font-size: 16px;
      line-height: 17.25px;
   }
}

@media screen and (max-width: 768px) {
   .features {
      flex-direction: column;
   }
   .features .content {
      justify-content: center;
      gap: 26px;
   }
   .features .content .item {
      padding: 20px 0;
      width: 100%;
      height: 250px;
   }
   .features .content .item .container {
      justify-content: space-between;
      height: 70%;
   }
   .features .content .item .text {
      height: 35%;
      bottom: 0;
   }
   .features .content .item .number {
      font-size: 24px;
      line-height: 24px;
   }
   .features .content .item h3 {
      font-size: 24px;
      line-height: 25.25px;
      margin: 15px 0;
   }
   .features .content .item p {
      font-size: 16px;
      line-height: 17.25px;
      margin: 8px 0;
   }
}

/* FOUNDER BLOCK */
#founder {
   padding-top: 10vh;
   background-color: #dbdbdb;
   height: 90vh;
   overflow: hidden;
   position: relative;
}
.founder {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.founder .text {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding-bottom: 10vh;
}
.founder .text h1 {
   font-size: 48px;
   font-weight: 400;
   line-height: 57.6px;
   color: #222121;
}
.founder .text h1 span {
   font-weight: 700;
}
.founder .text .p {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 20px;
   width: 700px;
}
.founder .text .p p {
   font-size: 20px;
   font-weight: 400;
   line-height: 24px;
   color: #a0a0a0;
}
#founder #calltoaction {
   background-color: #cfa46c;
   color: #222121;
   padding: 19px 20px;
   text-decoration: none;
   align-items: center;
   width: fit-content;
   font-weight: 500;
   font-size: 18px;
   line-height: 19.25px;
   border-radius: 20px;
   transition: 0.3s;
   border: none;
}

@media screen and (max-width: 1536px) {
   .founder .avatar img {
      width: 50%;
      bottom: 0;
      position: absolute;
   }
   .founder .text h1 {
      font-size: 48px;
      font-weight: 400;
      line-height: 57.6px;
      color: #222121;
   }
   .founder .text h1 span {
      font-weight: 700;
   }
   .founder .text .p {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      width: 700px;
   }
   .founder .text .p p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #a0a0a0;
   }
}
@media screen and (max-width: 768px) {
   #founder {
      padding-top: 2vh;
      height: 100vh;
   }
   .founder {
      display: block;
   }
   .founder .avatar {
      width: 100%;
   }
   .founder .avatar img {
      width: 100%;
   }
   .founder .text {
      width: 100%;
   }
   .founder .text h1 {
      font-size: 36px;
      line-height: 37.25px;
   }
   .founder .text .p {
      justify-content: space-between;
      width: 100%;
   }
   .founder .text .p p {
      font-size: 16px;
      line-height: 17.25px;
   }
   .founder .text a {
      margin: auto;
      padding: 4px 10px;
   }
}
@media screen and (max-width: 380px) {
   #founder {
      height: 130vh;
   }
}
/* FEEDBACKS BLOCK */
#feedbacks {
   padding-top: 10vh;
}
.feedbacks {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.feedbacks .slider {
   width: 100%;
}
.feedbacks .slider .feedback {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 20vh;
}
.feedbacks .slider .feedback #name {
   font-size: 20px;
   line-height: 23.68px;
   font-weight: 600;
   color: #f7f7f7;
}
.feedbacks .slider .feedback #feedback {
   font-size: 20px;
   line-height: 24px;
   font-weight: 400;
   color: #f7f7f7;
}
.feedbacks .slider .feedback #posted-at {
   font-size: 18px;
   line-height: 21.6px;
   font-weight: 400;
   color: #a0a0a0;
}
.feedbacks .buttons {
   right: 0;
   bottom: 0;
   text-align: right;
   gap: 20px;
}
.feedbacks .buttons img {
   margin: 0 10px;
   cursor: pointer;
}

@media screen and (max-width: 1367px) {
   .feedbacks .slider {
      position: relative;
   }
   .feedbacks .buttons {
      position: absolute;
      margin-bottom: -7%;
   }
   .feedbacks .slider .feedback {
      margin-bottom: 10vh;
   }
}
@media screen and (max-width: 768px) {
   .feedbacks {
      flex-direction: column;
   }
   #feedbacks #title {
      margin-bottom: 2vh;
   }
   .feedbacks .slider .feedback {
      height: 30vh;
   }
}
@media screen and (max-width: 380px) {
   .feedbacks .slider {
      height: 50vh;
   }
}

/* QA BLOCK */
#qa {
   margin-top: 10vh;
}
#qa .foryou .text .item {
   flex-direction: column;
   text-align: left;
   position: relative;
}
#qa .item h4 {
   /* min-width: 100%; */
   padding-right: 50px;
   width: 80%;
   margin: 0;
}
#qa .item p {
   text-align-last: left;
   color: #f7f7f7;
}
#qa img {
   position: absolute;
   top: 0;
   right: 0;
   transition: 0.3s;
}
@media screen and (max-width: 768px) {
   #qa .foryou .text {
      height: 50vh;
   }
}
@media screen and (max-width: 380px) {
   #qa .foryou .text {
      height: 70vh;
   }
}

/* FOOTER BLOCK */
#footer {
   margin-top: 15vh;
   padding-bottom: 5vh;
}
#footer #footer-logo {
   width: 100%;
   padding-bottom: 5vh;
   border-bottom: 0.5px solid #f7f7f7;
}
.footer {
   margin-top: 2%;
   display: flex;
   flex-direction: row;
   width: 100%;
}
#footer p,
a {
   font-size: 20px;
   line-height: 24px;
   font-weight: 400;
   color: #f7f7f7;
   text-decoration: none;
   margin: 10px;
   transition: 0.3s;
}
#footer a:hover {
   color: #cfa46c;
}
.footer .contacts {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   margin-right: 15%;
}
.footer .social {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   margin-right: 40%;
}
.footer #right {
   right: 0;
   font-size: 24px;
   line-height: 28.8px;
}

@media screen and (max-width: 1367px) {
   .footer .social {
      margin-right: 25%;
   }
}
@media screen and (max-width: 768px) {
   #footer p,
   a {
      font-size: 16px;
      line-height: 17.25px;
   }
   .footer .contact {
      margin-right: 0;
   }
   .footer .social {
      margin-right: 0;
   }
   .footer #right {
      text-align: center;
      font-size: 18px;
      margin-top: 5vh;
   }
   .footer {
      flex-wrap: wrap;
   }
}

/* POPUP Form */
.popup-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 999;
}

.popup {
   background-color: #222121;
   color: #f7f7f7; /* Light text color */
   border-radius: 15px;
   padding: 30px;
   max-width: 400px;
   width: 100%;
   text-align: center;
   position: relative;
}

.close-btn {
   position: absolute;
   top: 15px;
   right: 15px;
   background: none;
   border: none;
   font-size: 24px;
   color: #f7f7f7;
   cursor: pointer;
}

.popup h1 {
   font-size: 24px;
   line-height: 25.25px;
   margin-bottom: 20px;
}

.popup h1 span {
   color: #cfa46c;
}

/* Format buttons (Online/Offline/VIP) */
.format-buttons {
   display: flex;
   justify-content: space-around;
   width: 80%;
   margin: auto auto 20px auto;
}

.format-buttons button {
   background-color: #222121;
   color: #cfa46c;
   border: 1px solid #cfa46c;
   padding: 10px 20px;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
   font-family: "Gilroy", sans-serif;
}

.format-buttons button.active {
   background-color: #cfa46c;
   color: #f7f7f7;
   border: 1px solid #e0b57c;
}

.format-buttons button:hover {
   background-color: #cfa46c;
   color: #f7f7f7;
}

/* Form inputs */
.popup form input {
   width: 90%;
   padding: 10px 15px;
   margin: 15px auto 5px;
   border: none;
   border-radius: 5px;
   font-size: 16px;
   font-family: "Gilroy", sans-serif;
}

/* Submit button */
.submit-btn {
   background-color: #cfa46c;
   color: #222121;
   border: none;
   padding: 12px;
   border-radius: 5px;
   cursor: pointer;
   width: 100%;
   transition: background-color 0.3s ease;
   margin-top: 15px;
   font-family: "Gilroy", sans-serif;
   font-size: 16px;
}

.submit-btn:hover {
   background-color: #e0b57c;
}
button {
   cursor: pointer;
}
